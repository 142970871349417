<template>
  <div>
    <Crumbs> </Crumbs>
    <div class="mainList" style="top: 20px; bottom: -20px">
      <div class="mainList_operation clearfloat">
        <!-- 搜索 -->
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >标签名称：
              <el-input
                class="search_input"
                v-model.trim="parameter.labelName"
                clearable
                placeholder="请输入标签"
              />
            </span>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索
            </el-button>
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <!-- 新增标签按钮-->
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>
      <!-- 表格展示 -->
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" prop="labelName" label="标签名称" />
            <el-table-column align="center" prop="remark" label="备注" />
            <el-table-column prop="title" align="center" fixed="right" width="110" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
                <el-popconfirm
                  icon="el-icon-info"
                  title="确定删除该标签吗？"
                  @confirm="del(scope.row)"
                  @onConfirm="del(scope.row)"
                >
                  <el-button class="btn_danger" size="mini" slot="reference" type="text">
                    删除
                  </el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize" />
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
      <!-- 标签新增/编辑弹窗 -->
      <el-dialog append-to-body :title="title" :visible.sync="showEditDialog" width="30%">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="标签名称" prop="labelName" ref="labelName">
            <el-input v-model.trim="form.labelName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark" ref="remark">
            <el-input v-model="form.remark" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="showEditDialog = false">取 消</el-button>
          <el-button type="primary" @click="submit" :loading="editDialogLoading">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      loading: false,
      editDialogLoading: false, //修改、新增确认按钮
      showEditDialog: false, //修改、新增弹窗
      parameter: {
        //获取表格
        pageNow: 1,
        pageSize: 10,
        labelName: '',
      },
      pageSize: 0, //当前页面
      total: 0, //标签条数
      tableData: [], //全部标签数据
      title: '', //弹窗标题
      form: {}, //修改、新增的内容
      rules: {
        labelName: { required: true, message: '请输入标签名称', trigger: ['blur', 'change'] },
      },
      old: '',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    //获取标签列表
    this.getData()
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    // 获取标签列表
    getData() {
      this.loading = true
      this.$api.user
        .labelList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data.records
            this.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 修改
    edit(row) {
      this.form = row.deepClone()
      this.title = '修改标签'
      this.old = row.labelName
      this.showEditDialog = true
    },

    // 新增
    add() {
      this.form = {}
      this.title = '新增标签'
      this.showEditDialog = true
    },

    //提交新增或编辑
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.editDialogLoading = true
          if (this.form.id) {
            this.$api.user
              .editLabel(this.form)
              .then(res => {
                this.editDialogLoading = false
                if (res.msg == '该标签已存在' && this.old != this.form.labelName) {
                  this.$message.info(res.msg)
                } else {
                  this.getData()
                  this.$message.success('编辑成功！')
                  this.showEditDialog = false
                }
              })
              .catch(err => {
                this.editDialogLoading = false
                console.log(err)
              })
          } else {
            this.$api.user
              .addLabel(this.form)
              .then(res => {
                this.editDialogLoading = false
                if (res.msg == '该标签已存在') {
                  this.$message.info(res.msg)
                } else {
                  this.getData()
                  this.showEditDialog = false
                  this.$message.success('添加成功')
                }
              })
              .catch(err => {
                this.editDialogLoading = false
                console.log(err)
              })
          }
        }
      })
    },

    // 删除
    del(row) {
      this.loading = true
      this.$api.user
        .deleteLabel(row.id)
        .then(res => {
          this.loading = false
          this.$message.success('删除成功！')
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 表格行数
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    // 换页
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.parameter.pageNow = 1
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.labelName = ''
      this.pageChangeHandler(1)
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.mainList_operation {
  margin-top: 20px;
}

.search_input {
  width: 165px;
  margin-right: 10px;
}

.btn_danger {
  padding-left: 5px;
}

.dialog_table {
  margin: 10px 1px;
  width: 700px;
}

.mainList_operation {
  margin-top: 0px;
}

/deep/ .fullScreen.show {
  padding-top: 1000px;
}
</style>
